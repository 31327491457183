<template>
  <v-app>
    <GlobalHeader />

    <v-main>
      <slot />
    </v-main>

    <ContentWrapper>
      <GlobalFooter />
      <GlobalScrollTop />
    </ContentWrapper>
  </v-app>
</template>
